import "./Projects.css";
import { iosProjects, webProjects } from "../../data";
import ProjectView from "./ProjectView/ProjectView";
import WebPV from "./WebPV/WebPV";

const Project = (prop: {projectSection: React.MutableRefObject<null>}) => {
  return (
    <div className="pl" ref={prop.projectSection}>
      <div className="pl-list">
      {iosProjects.map((item) => (
          <div key={item.id} className="pl-list-ios-content">
            <ProjectView item={item}/>
          </div>
        ))}

        <div className="pl-list-content-web">
          <h1>Web Development</h1>
          {webProjects.map((item) => (
            <div key={item.id} className="pl-list-content-web-item">
              <WebPV item={item}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project;