import { MutableRefObject } from "react";
import "./NavBar.css";
import React, { useEffect } from 'react';

export interface NavBarProps {
  refList: {
    homeSection: MutableRefObject<null>,
    aboutSection: MutableRefObject<null>,
    projectsSection: MutableRefObject<null>
    contactSection: MutableRefObject<null>
  }
}

const NavBar = ({ refList }: NavBarProps) => {
  //hide nav bar when scrolling down
  const [tabHidden, setTabHidden] = React.useState(true)
  const [possision, setPossision] = React.useState(0)

  useEffect(() => {
    const controlNav = () => {
      const currentPoss = window.pageYOffset
      
      if (possision > currentPoss) {
        setTabHidden(false)
        setPossision(currentPoss)
      } else if (possision + 20 < currentPoss){
        setTabHidden(true)
        setPossision(currentPoss)
      }
    }

    window.addEventListener('scroll', controlNav)
    return () => {
      window.removeEventListener('scroll', controlNav)
    }
  }, [possision])

  const goToSection = (pageName: "home" | "about" | "projects" | "contact") => {
    console.log(refList.aboutSection)

    let page
    console.log("before", tabHidden)

    setTabHidden(true)

    console.log("after", tabHidden)
    switch (pageName) {
      case "home":
        page = refList.homeSection
        break;
      case "about":
        page = refList.aboutSection
        break;

      case "projects":
        page = refList.projectsSection
        break;
      case "contact":
        page = refList.contactSection
        break
      default:
        page = refList.homeSection
    }

    window.scrollTo({
      top: (page.current! as HTMLDivElement).offsetTop,
      behavior: 'smooth'
    })

    setTimeout(function () {
      setTabHidden(true)
  }, 800);
  }

  //scroll to about


  return (

    <div className={`nav-grid ${tabHidden  && 'nav-grid-hidden'}`}>
      <div className="nav-item" onClick={() => goToSection("home")}>Home</div>
      <div className="nav-item" onClick={() => goToSection("about")}>About</div>
      <div className="nav-item" onClick={() => goToSection('projects')}>Projects</div>
      <div className="nav-item" onClick={() => goToSection('contact')}>Contact</div>
      <a style={{textDecoration: "none"}} href={require('./ZJ-Resume.pdf')} target="blank" download='ZakimJaverResume'><div className="nav-item">Resume</div></a>
      <div className="nav-spacer"></div>
    </div>

  )
}

export default NavBar;