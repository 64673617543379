export interface IOSProject {
  id: number;
  title: string;
  link: Array<Link>;
  description: string;
  feature: Array<Features>;
  technology: Array<string>;
};

export interface Features {
  index: number;
  name: string;
  img: string;
  h1: string;
  p: string;
};
export interface Link{
  btnTitle: string,
  link: string
}

export interface WebProject{
  id: number;
  title: string;
  technology: Array<string>;
  description: string;
  link: Array<Link>;
  img: string;
}

export const webProjects: Array<WebProject> = [
  {
    id: 1,
    title: "Ecohive",
    technology: [
      "WordPress",
      "CSS",],
    description: "I worked with Ecohive’s graphic designer to develop the entire website on WordPress. I installed custom plug-ins, themes and coded custom CSS to meet the designer’s pixel perfect designs. I also set up the business emails for the company, managed the hosting portal as well as set up the e-commerce platform.",
    link: [{btnTitle: "VISIT WEBSITE", link: "https://www.ecohive.ca"}],
    img: require('./img/WebProjects/Ecohive.jpg')
  },
  {
    id: 2,
    title: "Torque Network",
    technology: [
      "WordPress",
      "CSS",],
    description: "Similar to Ecohive’s website I also developed the website for Torque Network on WordPress, installing custom plug-ins, themes and custom CSS. I worked alongside the public relations coordinator to optimize UI/UX. I set up business emails for the Torque Network team and also managed the hosting portal.",
    link: [{btnTitle: "Visit Website", link: "https://www.torquenetwork.io"}],
    img: require('./img/WebProjects/Torque.jpg')
  },
]


export const iosProjects: Array<IOSProject> = [
  {
    id: 2,
    title: "Torque Network",
    link: [
      {btnTitle: "WATCH DEMO", link: "https://www.youtube.com/watch?v=Xfz8ZNK8-Zk&t=1s"}, 
      {btnTitle: "VISIT WEBSITE", link: "https://www.torquenetwork.io"}
    ],
    description: "A social media application for the electric vehicle (EV) community that allows users to learn more about the EV world and make connections with other EV enthusiasts.",
    feature: [
      {
        index: 0,
        name: "EV Explore",
        img: require('./img/Projects/TorqueNetwork/Explore.jpg'),
        h1: "Match-Making",
        p: "Browse through your local EV community and industry to make new connections."
      },
      {
        index: 1,
        name: "Chat",
        img: require('./img/Projects/TorqueNetwork/Messaging.jpg'),
        h1: "Real-Time Messaging",
        p: "Chat and interact with connections via a unique messaging interface."
      },
      {
        index: 2,
        name: "EV Radar",
        img: require('./img/Projects/TorqueNetwork/EV-Radar.jpg'),
        h1: "Discover Nearby Users",
        p: "Enable geolocation settings to find Torque users within a 500m radius."
      },
      {
        index: 3,
        name: "Search",
        img: require('./img/Projects//TorqueNetwork/Connect.jpg'),
        h1: "Find Friends",
        p: "Easily connect with people you know or other Torque users using the search feature."
      },
      {
        index: 4,
        name: "Profile",
        img: require('./img/Projects/TorqueNetwork/ProfileManagement.jpg'),
        h1: "Profile Management",
        p: "Login using your google, Apple or email account and manage your profile in one portal."
      },
      {
        index: 5,
        name: "EV Cyclopedia",
        img: require('./img/Projects//TorqueNetwork/EV-Cylopedia.gif'),
        h1: "Learning Portal",
        p: "Study and reference key EV terms and learn more about the EV space through infographics."
      },
    ],
    technology: [
      "Swift 5",
      "Firebase",
      "Cloud Funtions",
      "GitHub",
      "Swift UI"
    ]
  },
  {
    id: 1,
    title: "Decidorama",
    link: [
      {btnTitle: "WATCH DEMO", link: "https://www.youtube.com"}, 
      {btnTitle: "< > Code", link: "https://bitbucket.org/code3x/decido/src/master/"}
    ],
    description: "A social media application for new college/university students that encourages users to perform meaningful activities with others.",
    feature: [
      {
        index: 0,
        name: "Discover",
        img: require('./img/Projects/Decidorama/ActivitySwiping.gif'),
        h1: "Browse Activities",
        p: "Students can discover exciting activities around them. These activities are created by other users and can be joined by swiping right."
      },
      {
        index: 1,
        name: "Analytics",
        img: require('./img/Projects/Decidorama/analytics.jpg'),
        h1: "View Statistics",
        p: "Users can review important data about the activity and group members prior to joining the activity."
      },
      {
        index: 2,
        name: "Image Recognition",
        img: require('./img/Projects/Decidorama/imageRecognition.gif'),
        h1: "Neural Network Classifier",
        p: "Leveraged Apples Core ML models to detect explicit images to ensure a safe enviroment for students."
      },
      {
        index: 3,
        name: "Messaging",
        img: require('./img/Projects/Decidorama/messaging.jpg'),
        h1: "Real-Time Group Messaging",
        p: "Once students group up, they are able to communicate with each other to plan their activities."
      },
      {
        index: 4,
        name: "Voting",
        img: require('./img/Projects/Decidorama/voting.gif'),
        h1: "Group Resolutions",
        p: "Members can collaborate to make decisions that impact the group collectively."
      },
      {
        index: 5,
        name: "Profile",
        img: require('./img/Projects/Decidorama/profile.jpg'),
        h1: "Profile Management",
        p: "Along with user login and profile creation, users can select preferences to optimize activity suggestions based on selected preferences and swipe history."
      },
    ],
    technology: [
      "Swift UI",
      "Firebase",
      "Cloud Funtions",
      "Bitbucket",
      "Jira"
    ]
  },
]