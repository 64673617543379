import { MutableRefObject} from "react";
import "./About.css";
const Me = require("../../img/Zakim.jpg")

const About = ( prop: {aboutSection: MutableRefObject<null>}) => {
  return (
    <div className="a" ref={prop.aboutSection}>
      <div className="a-img">
        <img src={Me} alt="My profile"/>
        <div className="a-img-tint" >
          
        </div>
      </div>
      <div className="a-content">
        <div className="a-content-container">
          <div className="a-title">About Me</div>
          <div className="a-info">
            Welcome to my portfolio, I am a software Engineer who loves to transform ideas into reality using code. I like exploring different areas of tech but I am mostly interested in building iOS applications. Having worked on the full SDLC of two major iOS projects, I feel confident in my abilities to code with Swift and wish to continue exploring and furthering my knowledge in this direction.
            <br />
            <br />
            As a software engineering and computer science graduate, I have constantly been learning new languages and technologies. For this reason, I am now able to pick up new skills very quickly. I have a relentless pursuit to solve problems and based on my experience, I know there are always creative solutions for every complex problem.
            <br />
            <br />
            Here are a few technologies I’ve recently been working with:
          </div>
          <div className="a-skill" >
              <ul>
                <li><span>Swift UI</span></li>
                <li><span>Swift 5</span></li>
                <li><span>Python</span></li>
                <li><span>TypeScript</span></li>
                <li><span>React</span></li>
                <li><span>WordPress</span></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

/**
 * 

 */