import "./ProjectView.css";
import CodeBox from './codeBox/CodeBox';
import BC from "./bottomContainer/BottomContainer"
import { IOSProject } from "../../../data";
import { useState } from "react";
import { useEffect } from "react";



const ProjectView = (prop: { item: IOSProject }) => {
  const [featureIndex, setFeatureIndex] = useState(0)    

  const featuresCount = prop.item.feature.length

  useEffect(() => {
    const interval = setInterval( () => {
      if (featureIndex >= featuresCount - 1){
        setFeatureIndex(0)
      }else{
        setFeatureIndex(prevFeature => prevFeature + 1)
      }
    }, 20000);
    return () => clearInterval(interval);

  }, [featureIndex, featuresCount])



const clickedFeature = (index: number) => {

  setFeatureIndex(index)
  
}

  return (
    <div className={`pv ${prop.item.id % 2 !== 0 && "pv-opposit"}`}>
      <div className={`pv-iPhone ${prop.item.id % 2 !== 0 && "pv-iPhone-opposit"}`}>
        <img alt="feature img" className="pv-featureImg"src={prop.item.feature[featureIndex].img}/>
        <img alt="Iphone Border" className="pv-iphoneBorder" src={require("../../../img/iphoneBorder.png")}/>
      </div>

      <div className={`pv-content ${prop.item.id % 2 !== 0 &&"pv-content-opposit"}`}>
        <h1>{prop.item.title}</h1>
        <p>{prop.item.description}</p>
        <div className="pv-feature">
          {prop.item.feature.map((item) => (
            <div key={JSON.stringify(item)} className={`pv-feature-item ${prop.item.feature[featureIndex].name === item.name && 'pv-feature-item-highlight'}`} onClick={() => clickedFeature(item.index)}>{item.name}</div>
          ))}
        </div>
        <CodeBox hTag={prop.item.feature[featureIndex].h1} pTag={prop.item.feature[featureIndex].p} />
        <div >
          <BC technology={prop.item.technology} links={prop.item.link}/>
        </div>
      </div>
    </div>
  );
};

export default ProjectView;
