import "./WebPV.css";
import { WebProject } from "../../../data";
import CodeBox from '../ProjectView/codeBox/CodeBox';
import BC from "../ProjectView/bottomContainer/BottomContainer"


const ProjectView = (prop: { item: WebProject }) => {
  return (
    <div className="wpv">
        <div className="wpv-img">
        <img alt="webImg" src={prop.item.img} />
        </div>
        <div className="wpv-code">
          <CodeBox hTag={prop.item.title} pTag={prop.item.description} />
        </div>
        <div className="wpv-bottom-container">
            <BC technology={prop.item.technology} links={prop.item.link}/>
              
        </div>
    </div>
  );
};

export default ProjectView;
