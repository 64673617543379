import { MutableRefObject} from "react";
import "./Contact.css";
const ContactImg = require("../../img/Contact.png")

const Contact = ( prop: {contactSection: MutableRefObject<null>}) => {
  return (
    <div className="c" ref={prop.contactSection}>
        <h1>Contact</h1>
        <p>I recently graduated with a 3.7 GPA from the Honours Bachelor of Commuter Science - Mobile Computing program at Sheridan College in December 2021. I am currently looking for a full-time software developer opportunity specializing in application development. Feel free to get in touch if you’re hiring!</p>
        <a href="mailto:javerzakim@gmail.com?subject=Hey%20Zakim%20let%27s%20connect"><img src={ContactImg} alt="Contact img"/></a>
    </div>
  );
};

export default Contact;
