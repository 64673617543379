
import "./CodeBox.css"
import { useState, useEffect, useRef, useMemo } from "react";



export default function CodeBox(prop: { hTag: string, pTag: string }) {
    const index = useRef(0);
    const subIndex = useRef(0);
    const speed = useRef(200);
    const [blink, setBlink] = useState(false);

    const [h1Obj, setH1Obj] = useState({
        tag: "",
        tittle: "",
        endTag: false,
        cursorTag: true,
        cursorTittle: false
    })

    const [pObj, setPObj] = useState({
        tag: "",
        text: "",
        endTag: false,
        cursorTag: false,
        cursorText: false
    })

    const word = useMemo(() => {return ["<h1>", prop.hTag, "<p>", prop.pTag]}, [prop.hTag, prop.pTag])

    //used to reset the code box when the props change
    useEffect(() => {
        console.log("props have changed");
        index.current = 0;
        subIndex.current = 0;
        speed.current = 200

        setH1Obj({
            tag: "",
            tittle: "",
            endTag: false,
            cursorTag: true,
            cursorTittle: false
        })
        setPObj({
            tag: "",
            text: "",
            endTag: false,
            cursorTag: false,
            cursorText: false
        })

    }, [prop])

    //typewritter
    useEffect(() => {
        const timeout = setTimeout(() => {
            const typeLetter = () => {
                switch (index.current){
                    case 0:
                        //h1 tag
                        if(subIndex.current <= word[index.current].length - 1){
                            //printing tag letter
                            setH1Obj((prev) => ({...prev, tag: prev.tag.concat(word[index.current][subIndex.current])}))
                            subIndex.current++;
                        }else{
                            //show end of tag, setup to printing h1 tittle next
                            subIndex.current = 0;
                            index.current++;
                            speed.current = 500;
                            setH1Obj((prev) => ({...prev, endTag: true, cursorTag: false, cursorTittle: true}));
                        }
                        break;
                    case 1:
                        //h1 tittle
                        if(subIndex.current <= word[index.current].length - 1){
                            //printing tittle letter
                            speed.current = 100;
                            setH1Obj((prev) => ({...prev, tittle: prev.tittle.concat(word[index.current][subIndex.current])}))
                            subIndex.current++;
                        }else{
                            //setup to printing P tag next
                            subIndex.current = 0
                            index.current++
                            speed.current = 900;
                            setH1Obj((prev) => ({...prev, endTag: true, cursorTittle: false}))
                            setPObj((prev) => ({...prev, cursorTag: true}))
                        }
                        break;
                    case 2:
                        //p tag
                        if(subIndex.current <= word[index.current].length - 1){
                            //printing p tag letter
                            speed.current = 200;
                            setPObj((prev) => ({...prev, tag: prev.tag.concat(word[index.current][subIndex.current])}))
                            subIndex.current++;
                        }else{
                            //show end of p tag, setup p text printing next
                            subIndex.current = 0
                            index.current++
                            speed.current = 500;
                            setPObj((prev) => ({...prev, endTag: true, cursorTag: false, cursorText: true}))
                        }
                        break;
                    case 3:
                        //p text
                        if(subIndex.current <= word[index.current].length - 1){
                            //printing p text letter
                            speed.current = 20;
                            setPObj((prev) => ({...prev, text: prev.text.concat(word[index.current][subIndex.current])}))
                            subIndex.current++;
                        }else{
                            //all prints are donw"
                            console.log("Last letter was printed");
                            
                        }
                        break;
                    default:
                        console.log("all prints are donw");

                        break;
                }
            }
            typeLetter();
        }, speed.current);

        return () => {
            clearTimeout(timeout)
        }
    }, [word, pObj, h1Obj])

    // blinker
    useEffect(() => {
        const timeout2 = setTimeout(() => {
            setBlink((prev) => !prev);
        }, 200);

        return () => clearTimeout(timeout2);
    }, [blink]);


    return (
            <div className="pv-code"> 
                <span className="tag">{h1Obj.tag}</span>{h1Obj.cursorTag && <span className={`${blink  && 'isHidden'}`}>|</span>}<span className="h1Text">{h1Obj.tittle}</span>{h1Obj.cursorTittle && <span className={`${blink  && 'isHidden'}`}>|</span>}<span className="tag">{`${h1Obj.endTag ?  "</h1>" : ""}`}</span>
                <br/><br/>
                <div className="tagSpacing"><span className="tag">{pObj.tag}</span>{pObj.cursorTag && <span className={`${blink  && 'isHidden'}`}>|</span>}<div className="pTextSpacing"><span className="pText">{pObj.text}</span>{pObj.cursorText && <span className={`${blink  && 'isHidden'}`}>|</span>}</div><span className="tag">{`${pObj.endTag ? "</p>" : ""}`}</span></div>
            </div>
    );
}
