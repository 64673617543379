import "./Intro.css";
import Typewriter from 'typewriter-effect';
import { NavBarProps } from "../navbar/NavBar";
const Menue = require("../../img/PhoneMenue.png")


const Intro = (prop: { homeSection: React.MutableRefObject<null>, navProp: NavBarProps }) => {
  const goToSection = (pageName: "about" | "projects" | "contact" | "resume") => {

    if(pageName === "resume"){
      window.open(require('../navbar/ZJ-Resume.pdf'));
      return
    }

    let page

    switch (pageName) {
      case "about":
        page = prop.navProp.refList.aboutSection
        break;

      case "projects":
        page = prop.navProp.refList.projectsSection
        break;

      case "contact":
        page = prop.navProp.refList.contactSection
        break;
      default:
        page = prop.navProp.refList.homeSection
    }

    window.scrollTo({
      top: (page.current! as HTMLDivElement).offsetTop,
      behavior: "smooth"
    })
  }

  return (
    <div className="i-grid" ref={prop.homeSection}>
      <div className="i-container">
        <h2 className="i-intro">Hi, my name is</h2>
        <h1 className="i-name">Zakim Javer.</h1>
        <h1 className="i-quote">You dream it, I build it!</h1>
        <div className="i-description">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(300)
                .typeString('I am a software engineer that loves to bring ideas to life.')
                .pauseFor(500)
                .typeString(' I specializing in creating stylish, modern iOS applications with swift UI and Swift 5.')
                .stop()
                .start();
            }}
            options={{
              delay: 15
            }}
          />
        </div>
      </div>

      <div className="i-menue">
        <div className="i-menue-container">
          <img src={Menue} className="i-menue-img" alt="Menue"/>
          <div className="i-menue-item-container">
            <div className="i-menue-item" onClick={() => goToSection("about")}>About</div>
            <div className="i-menue-item" onClick={() => goToSection("projects")}>Projects</div>
            <div className="i-menue-item" onClick={() => goToSection("contact")}>Contact</div>
            <div className="i-menue-item" onClick={() => goToSection("resume")}>Resume</div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Intro;

