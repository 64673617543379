import "./BottomContainer.css";
import { Link } from "../../../../data";


const ProjectView = (prop: {technology: Array<string>, links: Array<Link> }) => {
  return (
      <div className="bc">
          <div className="bc-buttons">
              {prop.links.map((item) => (
                <a key={JSON.stringify(item)} href={item.link} target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                  <div className="bc-btn">{item.btnTitle}</div>
                </a>
              ))}
            
            
          </div>
          <div className="bc-title">Leveraged technology:</div>
          <div className="bc-tech">
            <ul>
              {prop.technology.map((item) => (
                <li key={JSON.stringify(item)} className="bc-technology-item"><span>{item}</span></li>
              ))}
            </ul>
          </div>
      </div>
  );
};

export default ProjectView;
