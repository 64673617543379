import React, { useRef } from 'react';
import './App.css';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Intro from './components/intro/Intro';
import NavBar, { NavBarProps } from './components/navbar/NavBar';
import Projects from './components/projects/Projects';


function App() {
  //scroll to about
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);

  const refList: NavBarProps["refList"] = {
    homeSection: homeRef,
    aboutSection: aboutRef,
    projectsSection: projectsRef,
    contactSection: contactRef
  }



  return (
    <div className="App" style={{
      backgroundColor: "#1D2323",
      color: "white",
    }}
    >
      <Intro homeSection={homeRef} navProp={{ refList }} />
      <NavBar refList={refList} />
      <About aboutSection={aboutRef} />
      <Projects projectSection={projectsRef} />
      <Contact contactSection={contactRef} />
      

    </div>
  );
}

export default App;
